import { writable } from "svelte/store";
export let schedules = [
  {
    short: "2",
    title: "4-Day Rotation: 2-Long, 1-Short + Choice",
    color: "#311B92",
    links: [
      {
        url: "https://docs.google.com/document/u/0/d/1A5GVp0J9ApqHHw12t1UsDeenWQ4ecCbEs9qtz16owIU/edit",
        title: "Overview Google Doc",
      },
      { url: "https://youtu.be/8Vy2cgczO8A", title: "Screencast walk-through" },
    ],
    meta: {
      blockType: "4-day rotation",
      crossoverBlocks: 3,
    },
    blocks: [
      { name: "Advisory/WIN", color: "#c5cae9", total: 25, academic: true },
      { name: "Lunch", color: "#fbfefd", total: 25 },
      { name: "B", color: "#ffccbc", total: 48.75, academic: true },
      { name: "A", color: "#f1f8e9", total: 48.75, academic: true },
      { name: "C", color: "#ffebee", total: 48.75, academic: true },
      { name: "D", color: "#f9fbe7", total: 48.75, academic: true },
      { name: "E", color: "#d1c4e9", total: 48.75, academic: true },
      { name: "F", color: "#e3f2fd", total: 48.75, academic: true },
      { name: "Choice", color: "#ffad0a", total: 13.75, academic: true },
      { name: "Recess", color: "#ffffff", total: 15 },
    ],
    days: [
      {
        name: "HSD1",
        repeats: 1,
        blocks: [
          {
            duration: 55,
            passing: 0,
            id: 13,
            offset: 0,
            height: 165,
            block: {
              name: "A",
              color: "#f1f8e9",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 18,
            offset: 165,
            height: 45,
            block: { name: "Recess", color: "#ffffff", total: 15 },
          },
          {
            duration: 85,
            passing: 5,
            offset: 210,
            height: 255,
            id: 7,
            block: {
              name: "B",
              color: "#ffccbc",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 480,
            height: 165,
            id: 8,
            block: {
              name: "C",
              color: "#ffebee",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 660,
            height: 165,
            id: 9,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 840,
            height: 75,
            id: 12,
            block: { name: "Lunch", color: "#fbfefd", total: 25 },
          },
          {
            duration: 25,
            passing: 5,
            offset: 930,
            height: 75,
            id: 11,
            block: {
              name: "Advisory/WIN",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            id: 10,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 48.75,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "HSD2",
        repeats: 1,
        blocks: [
          {
            duration: 55,
            passing: 0,
            id: 13,
            offset: 0,
            height: 165,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 19,
            offset: 165,
            height: 45,
            block: { name: "Recess", color: "#ffffff", total: 15 },
          },
          {
            duration: 85,
            passing: 5,
            offset: 210,
            height: 255,
            id: 7,
            block: {
              name: "C",
              color: "#ffebee",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 480,
            height: 165,
            id: 8,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 660,
            height: 165,
            id: 9,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 840,
            height: 75,
            id: 12,
            block: { name: "Lunch", color: "#fbfefd", total: 25 },
          },
          {
            duration: 25,
            passing: 5,
            offset: 930,
            height: 75,
            id: 11,
            block: {
              name: "Advisory/WIN",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            id: 10,
            block: {
              name: "B",
              color: "#ffccbc",
              total: 48.75,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "HSD3",
        repeats: 1,
        blocks: [
          {
            duration: 55,
            passing: 0,
            id: 13,
            offset: 0,
            height: 165,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 165,
            height: 45,
            block: { name: "Recess", color: "#ffffff", total: 15 },
          },
          {
            duration: 85,
            passing: 5,
            offset: 210,
            height: 255,
            id: 7,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 480,
            height: 165,
            id: 8,
            block: {
              name: "B",
              color: "#ffccbc",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 660,
            height: 165,
            id: 10,
            block: {
              name: "C",
              color: "#ffebee",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 840,
            height: 75,
            id: 12,
            block: { name: "Lunch", color: "#fbfefd", total: 25 },
          },
          {
            duration: 25,
            passing: 5,
            offset: 930,
            height: 75,
            id: 11,
            block: {
              name: "Advisory/WIN",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            id: 9,
            block: {
              name: "A",
              color: "#f1f8e9",
              total: 48.75,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "HSD4",
        repeats: 1,
        blocks: [
          {
            duration: 85,
            passing: 0,
            offset: 0,
            height: 255,
            id: 7,
            block: {
              name: "A",
              color: "#f1f8e9",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 21,
            offset: 255,
            height: 45,
            block: { name: "Recess", color: "#ffffff", total: 15 },
          },
          {
            duration: 85,
            passing: 5,
            offset: 300,
            height: 255,
            id: 8,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 48.75,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 570,
            height: 165,
            id: 10,
            block: {
              name: "Choice",
              color: "#ffad0a",
              total: 13.75,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 750,
            height: 75,
            id: 12,
            block: { name: "Lunch", color: "#fbfefd", total: 25 },
          },
          {
            duration: 25,
            passing: 5,
            offset: 840,
            height: 75,
            id: 11,
            block: {
              name: "Advisory/WIN",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 85,
            passing: 5,
            offset: 930,
            height: 255,
            id: 9,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 48.75,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
    ],
  },

  {
    short: "4",
    color: "#0D47A1",
    title: "9 Day Rotation (2-Long, 3-short, 3-choice)",
    links: [
      {
        url: "https://docs.google.com/document/d/1bPnpNK3SmS_xRZtvFpf6OQkj5Uliz72HsVpdfrkK9DI/edit#",
        title: "Overview Google Doc",
      },
      { url: "https://youtu.be/2g2C7rpukB4", title: "Screencast walk-through" },
    ],
    meta: {
      blockType: "9-day rotation",
      crossoverBlocks: 2,
    },
    blocks: [
      {
        name: "Advisory",
        color: "#c5cae9",
        total: 19.999999999999996,
        textcolor: "#fcfcfc",
        academic: true,
      },
      {
        name: "Lunch",
        color: "#25511f",
        total: 19.999999999999996,
        textcolor: "#fafbf9",
      },
      {
        name: "A",
        color: "#c8e9f4",
        total: 48.888888888888886,
        academic: true,
      },
      {
        name: "D",
        color: "#fdd8f8",
        total: 48.888888888888886,
        academic: true,
      },
      {
        name: "B",
        color: "#ffe485",
        total: 48.888888888888886,
        academic: true,
      },
      { name: "C", color: "#dbfdba", total: 48.8888888888889, academic: true },
      {
        color: "#d1c4e9",
        name: "E",
        total: 48.888888888888886,
        academic: true,
      },
      {
        color: "#ffb561",
        name: "X",
        total: 16.666666666666664,
        academic: true,
      },
      { name: "Recess", color: "#efebe9", total: 14.999999999999996 },
      {
        color: "#f3e5f5",
        name: "F",
        total: 48.888888888888886,
        academic: true,
      },
    ],
    days: [
      {
        name: "D1",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "A",
              color: "#c8e9f4",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              name: "B",
              color: "#ffe485",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              name: "C",
              color: "#dbfdba",
              total: 48.8888888888889,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              name: "D",
              color: "#fdd8f8",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              color: "#d1c4e9",
              name: "E",
              total: 48.888888888888886,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D2",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              color: "#d1c4e9",
              name: "E",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              name: "A",
              color: "#c8e9f4",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              name: "D",
              color: "#fdd8f8",
              total: 48.888888888888886,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              color: "#f3e5f5",
              name: "F",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              name: "B",
              color: "#ffe485",
              total: 48.888888888888886,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D3",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "B",
              color: "#ffe485",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              color: "#d1c4e9",
              name: "E",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              color: "#f3e5f5",
              name: "F",
              total: 48.888888888888886,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              name: "C",
              color: "#dbfdba",
              total: 48.8888888888889,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              color: "#ffb561",
              name: "X",
              total: 16.666666666666664,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D4",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "A",
              color: "#c8e9f4",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              name: "B",
              color: "#ffe485",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              name: "C",
              color: "#dbfdba",
              total: 48.8888888888889,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              name: "D",
              color: "#fdd8f8",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              color: "#d1c4e9",
              name: "E",
              total: 48.888888888888886,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D5",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              color: "#f3e5f5",
              name: "F",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              name: "A",
              color: "#c8e9f4",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              name: "D",
              color: "#fdd8f8",
              total: 48.888888888888886,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              name: "C",
              color: "#dbfdba",
              total: 48.8888888888889,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              name: "B",
              color: "#ffe485",
              total: 48.888888888888886,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D6",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "A",
              color: "#c8e9f4",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              color: "#d1c4e9",
              name: "E",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              color: "#f3e5f5",
              name: "F",
              total: 48.888888888888886,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              name: "D",
              color: "#fdd8f8",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              color: "#ffb561",
              name: "X",
              total: 16.666666666666664,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D7",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "D",
              color: "#fdd8f8",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              name: "B",
              color: "#ffe485",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              name: "C",
              color: "#dbfdba",
              total: 48.8888888888889,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              color: "#f3e5f5",
              name: "F",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              color: "#d1c4e9",
              name: "E",
              total: 48.888888888888886,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D8",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              color: "#f3e5f5",
              name: "F",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              name: "A",
              color: "#c8e9f4",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              name: "D",
              color: "#fdd8f8",
              total: 48.888888888888886,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              name: "C",
              color: "#dbfdba",
              total: 48.8888888888889,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              name: "B",
              color: "#ffe485",
              total: 48.888888888888886,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
      {
        name: "D9",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 5,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "C",
              color: "#dbfdba",
              total: 48.8888888888889,
              academic: true,
            },
          },
          {
            duration: 80,
            passing: 0,
            offset: 165,
            height: 240,
            id: 8,
            block: {
              color: "#d1c4e9",
              name: "E",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            id: 20,
            offset: 405,
            height: 45,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 14.999999999999996,
            },
          },
          {
            duration: 80,
            passing: 5,
            offset: 450,
            height: 240,
            block: {
              color: "#f3e5f5",
              name: "F",
              total: 48.888888888888886,
              academic: true,
            },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 705,
            height: 150,
            id: 9,
            block: {
              name: "A",
              color: "#c8e9f4",
              total: 48.888888888888886,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 870,
            height: 60,
            id: 10,
            block: {
              name: "Lunch",
              color: "#25511f",
              total: 19.999999999999996,
              textcolor: "#fafbf9",
            },
          },
          {
            duration: 20,
            passing: 5,
            offset: 945,
            height: 60,
            id: 11,
            block: {
              name: "Advisory",
              color: "#4d24bc",
              total: 19.999999999999996,
              textcolor: "#fcfcfc",
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 5,
            offset: 1020,
            height: 150,
            id: 12,
            block: {
              color: "#ffb561",
              name: "X",
              total: 16.666666666666664,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1185,
      },
    ],
  },
  {
    short: "3",
    color: "#0277BD",
    title: "3-Day Rotation (2-Long + Choice)",
    links: [
      {
        url: "https://docs.google.com/document/u/0/d/1lFOW3fKgnOW4oPTtVw9bduIuYb9WkhBQwfyfxKV3p38/edit",
        title: "Overview Google Doc",
      },
      {
        url: "https://youtu.be/vX59ZP100M8",
        title: "Screencast walk-through (for MS",
      },
      {
        url: "https://www.youtube.com/watch?v=dB6ciD3fedg",
        title: "Screencast walk-through (for HS)",
      },
    ],
    meta: {
      blockType: "3 day rotation (+weekly choice)",
      choiceOverlay: [0, 0, 75, 0, 0],
      crossoverBlocks: 3,
    },
    blocks: [
      { name: "Advisory", color: "#c5cae9", total: 30, academic: true },
      { name: "Lunch", color: "#fafafa", total: 30 },
      { name: "A", color: "#ffccbc", total: 50 * 0.95, academic: true },
      { name: "B", color: "#f1f8e9", total: 50 * 0.95, academic: true },
      { name: "C", color: "#ffebee", total: 50 * 0.95, academic: true },
      { name: "D", color: "#f9fbe7", total: 50 * 0.95, academic: true },
      { name: "E", color: "#d1c4e9", total: 50 * 0.95, academic: true },
      { name: "F", color: "#e3f2fd", total: 50 * 0.95, academic: true },
      { name: "Choice", color: "#ffb029", total: 75 / 5, academic: true },
      { color: "#ffffff", name: "Long Pass", total: 15 },
    ],
    days: [
      {
        name: "Day 1",
        repeats: 1,
        blocks: [
          {
            duration: 75,
            passing: 0,
            offset: 0,
            height: 225,
            id: 7,
            block: { name: "A", color: "#ffccbc", total: 50, academic: true },
          },
          {
            duration: 15,
            passing: 0,
            id: 13,
            offset: 225,
            height: 45,
            block: { color: "#ffffff", name: "Long Pass", total: 15 },
          },
          {
            duration: 75,
            passing: 5,
            id: 14,
            offset: 270,
            height: 225,
            block: { name: "B", color: "#f1f8e9", total: 50, academic: true },
          },
          {
            duration: 75,
            passing: 5,
            offset: 510,
            height: 225,
            id: 8,
            block: { name: "C", color: "#ffebee", total: 50, academic: true },
          },
          {
            duration: 30,
            passing: 5,
            offset: 750,
            height: 90,
            id: 9,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 30,
              academic: true,
            },
          },
          {
            duration: 30,
            passing: 5,
            offset: 855,
            height: 90,
            id: 10,
            block: { name: "Lunch", color: "#fafafa", total: 30 },
          },
          {
            duration: 75,
            passing: 5,
            offset: 960,
            height: 225,
            id: 11,
            block: { name: "D", color: "#f9fbe7", total: 50, academic: true },
          },
        ],
        height: 1200,
        start: 480,
      },
      {
        name: "Day 2",
        repeats: 1,
        blocks: [
          {
            duration: 75,
            passing: 0,
            offset: 0,
            height: 225,
            id: 7,
            block: { name: "E", color: "#d1c4e9", total: 50, academic: true },
          },
          {
            duration: 15,
            passing: 0,
            id: 13,
            offset: 225,
            height: 45,
            block: { color: "#ffffff", name: "Long Pass", total: 15 },
          },
          {
            duration: 75,
            passing: 5,
            id: 14,
            offset: 270,
            height: 225,
            block: { name: "F", color: "#e3f2fd", total: 50, academic: true },
          },
          {
            duration: 75,
            passing: 5,
            offset: 510,
            height: 225,
            id: 8,
            block: { name: "A", color: "#ffccbc", total: 50, academic: true },
          },
          {
            duration: 30,
            passing: 5,
            offset: 750,
            height: 90,
            id: 9,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 30,
              academic: true,
            },
          },
          {
            duration: 30,
            passing: 5,
            offset: 855,
            height: 90,
            id: 10,
            block: { name: "Lunch", color: "#fafafa", total: 30 },
          },
          {
            duration: 75,
            passing: 5,
            offset: 960,
            height: 225,
            id: 11,
            block: { name: "B", color: "#f1f8e9", total: 50, academic: true },
          },
        ],
        start: 480,
        height: 1350,
      },
      {
        name: "Day 3",
        repeats: 1,
        blocks: [
          {
            duration: 75,
            passing: 0,
            offset: 0,
            height: 225,
            id: 7,
            block: { name: "C", color: "#ffebee", total: 50, academic: true },
          },
          {
            duration: 15,
            passing: 0,
            id: 13,
            offset: 225,
            height: 45,
            block: { color: "#ffffff", name: "Long Pass", total: 15 },
          },
          {
            duration: 75,
            passing: 5,
            id: 14,
            offset: 270,
            height: 225,
            block: { name: "D", color: "#f9fbe7", total: 50, academic: true },
          },
          {
            duration: 75,
            passing: 5,
            offset: 510,
            height: 225,
            id: 8,
            block: { name: "E", color: "#d1c4e9", total: 50, academic: true },
          },
          {
            duration: 30,
            passing: 5,
            offset: 750,
            height: 90,
            id: 9,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 30,
              academic: true,
            },
          },
          {
            duration: 30,
            passing: 5,
            offset: 855,
            height: 90,
            id: 10,
            block: { name: "Lunch", color: "#fafafa", total: 30 },
          },
          {
            duration: 75,
            passing: 5,
            offset: 960,
            height: 225,
            id: 11,
            block: { name: "F", color: "#e3f2fd", total: 50, academic: true },
          },
        ],
        start: 480,
        height: 1200,
      },
    ],
  },
  {
    short: "1",
    title: "6 Day Rotation",
    color: "#7B1FA2",
    links: [
      {
        url: "https://docs.google.com/document/d/1mBQOLOnPVoqyI44tAdPV6BWeY_nTpL0eSP_OuG9yc-o/edit?usp=sharing",
        title: "Overview Google Doc",
      },
      {
        url: "https://drive.google.com/file/d/1R9YQ05tm7-iNbEpQmLQOLqnQPZVZs7SV/view?usp=sharing",
        title: "Screencast walk-through",
      },
    ],
    meta: {
      blockType: "6 Day Rotation + weekly choice",
      choiceOverlay: [0, 0, 55, 0, 0],
      crossoverBlocks: 2,
    },
    blocks: [
      {
        name: "Advisory",
        color: "#c5cae9",
        total: 24.999999999999993,
        academic: true,
      },
      { name: "Lunch", color: "#e0f2f1", total: 15 },
      {
        name: "A",
        color: "#ffccbc",
        total: 52.49999999999999 * 0.96507,
        academic: true,
      },

      {
        name: "B",
        color: "#f1f8e9",
        total: 52.49999999999999 * 0.96507,
        academic: true,
      },
      { name: "C", color: "#ffebee", total: 52.5 * 0.96507, academic: true },
      {
        name: "D",
        color: "#f9fbe7",
        total: 52.49999999999999 * 0.96507,
        academic: true,
      },
      {
        name: "E",
        color: "#d1c4e9",
        total: 52.49999999999999 * 0.96507,
        academic: true,
      },
      {
        name: "F",
        color: "#e3f2fd",
        total: 52.49999999999999 * 0.96507,
        academic: true,
      },
      {
        name: "Recess",
        color: "#efebe9",
        total: 4.999999999999999 * 0.96507,
        academic: true,
      },
      { name: "Choice", color: "orange", total: 55 / 5, academic: true },
    ],
    days: [
      {
        name: "Day 1",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            offset: 0,
            height: 75,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.999999999999993,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 90,
            height: 225,
            id: 8,
            block: { name: "A", color: "#ffccbc", total: 52.49999999999999 },
          },
          {
            duration: 5,
            passing: 5,
            id: 17,
            offset: 330,
            height: 15,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 4.999999999999999,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 360,
            height: 225,
            id: 9,
            block: { name: "B", color: "#f1f8e9", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 600,
            height: 165,
            id: 10,
            block: { name: "C", color: "#ffebee", total: 52.5 },
          },
          {
            duration: 15,
            passing: 5,
            offset: 780,
            height: 45,
            id: 11,
            block: { name: "Lunch", color: "#e0f2f1", total: 15 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 840,
            height: 165,
            id: 12,
            block: { name: "D", color: "#f9fbe7", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            block: { name: "E", color: "#d1c4e9", total: 52.49999999999999 },
            id: 16,
          },
        ],
        height: 1200,
        start: 480,
      },
      {
        name: "Day 2",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            offset: 0,
            height: 75,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.999999999999993,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 90,
            height: 225,
            id: 8,
            block: { name: "F", color: "#e3f2fd", total: 52.49999999999999 },
          },
          {
            duration: 5,
            passing: 5,
            id: 17,
            offset: 330,
            height: 15,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 4.999999999999999,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 360,
            height: 225,
            id: 9,
            block: { name: "A", color: "#ffccbc", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 600,
            height: 165,
            id: 10,
            block: { name: "B", color: "#f1f8e9", total: 52.49999999999999 },
          },
          {
            duration: 15,
            passing: 5,
            offset: 780,
            height: 45,
            id: 11,
            block: { name: "Lunch", color: "#e0f2f1", total: 15 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 840,
            height: 165,
            id: 12,
            block: { name: "C", color: "#ffebee", total: 52.5 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            block: { name: "D", color: "#f9fbe7", total: 52.49999999999999 },
            id: 16,
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "Day 3",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            offset: 0,
            height: 75,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.999999999999993,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 90,
            height: 225,
            id: 8,
            block: { name: "E", color: "#d1c4e9", total: 52.49999999999999 },
          },
          {
            duration: 5,
            passing: 5,
            id: 17,
            offset: 330,
            height: 15,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 4.999999999999999,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 360,
            height: 225,
            id: 9,
            block: { name: "F", color: "#e3f2fd", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 600,
            height: 165,
            id: 10,
            block: { name: "A", color: "#ffccbc", total: 52.49999999999999 },
          },
          {
            duration: 15,
            passing: 5,
            offset: 780,
            height: 45,
            id: 11,
            block: { name: "Lunch", color: "#e0f2f1", total: 15 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 840,
            height: 165,
            id: 12,
            block: { name: "B", color: "#f1f8e9", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            block: { name: "C", color: "#ffebee", total: 52.5 },
            id: 16,
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "Day 4",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            offset: 0,
            height: 75,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.999999999999993,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 90,
            height: 225,
            id: 8,
            block: { name: "D", color: "#f9fbe7", total: 52.49999999999999 },
          },
          {
            duration: 5,
            passing: 5,
            id: 17,
            offset: 330,
            height: 15,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 4.999999999999999,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 360,
            height: 225,
            id: 9,
            block: { name: "E", color: "#d1c4e9", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 600,
            height: 165,
            id: 10,
            block: { name: "F", color: "#e3f2fd", total: 52.49999999999999 },
          },
          {
            duration: 15,
            passing: 5,
            offset: 780,
            height: 45,
            id: 11,
            block: { name: "Lunch", color: "#e0f2f1", total: 15 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 840,
            height: 165,
            id: 12,
            block: { name: "A", color: "#ffccbc", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            block: { name: "B", color: "#f1f8e9", total: 52.49999999999999 },
            id: 16,
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "Day 5",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            offset: 0,
            height: 75,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.999999999999993,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 90,
            height: 225,
            id: 8,
            block: { name: "C", color: "#ffebee", total: 52.5 },
          },
          {
            duration: 5,
            passing: 5,
            id: 17,
            offset: 330,
            height: 15,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 4.999999999999999,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 360,
            height: 225,
            id: 9,
            block: { name: "D", color: "#f9fbe7", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 600,
            height: 165,
            id: 10,
            block: { name: "E", color: "#d1c4e9", total: 52.49999999999999 },
          },
          {
            duration: 15,
            passing: 5,
            offset: 780,
            height: 45,
            id: 11,
            block: { name: "Lunch", color: "#e0f2f1", total: 15 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 840,
            height: 165,
            id: 12,
            block: { name: "F", color: "#e3f2fd", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            block: { name: "A", color: "#ffccbc", total: 52.49999999999999 },
            id: 16,
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "Day 6",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            offset: 0,
            height: 75,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.999999999999993,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 90,
            height: 225,
            id: 8,
            block: { name: "B", color: "#f1f8e9", total: 52.49999999999999 },
          },
          {
            duration: 5,
            passing: 5,
            id: 17,
            offset: 330,
            height: 15,
            block: {
              name: "Recess",
              color: "#efebe9",
              total: 4.999999999999999,
            },
          },
          {
            duration: 75,
            passing: 5,
            offset: 360,
            height: 225,
            id: 9,
            block: { name: "C", color: "#ffebee", total: 52.5 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 600,
            height: 165,
            id: 10,
            block: { name: "D", color: "#f9fbe7", total: 52.49999999999999 },
          },
          {
            duration: 15,
            passing: 5,
            offset: 780,
            height: 45,
            id: 11,
            block: { name: "Lunch", color: "#e0f2f1", total: 15 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 840,
            height: 165,
            id: 12,
            block: { name: "E", color: "#d1c4e9", total: 52.49999999999999 },
          },
          {
            duration: 55,
            passing: 5,
            offset: 1020,
            height: 165,
            block: { name: "F", color: "#e3f2fd", total: 52.49999999999999 },
            id: 16,
          },
        ],
        start: 480,
        height: 1200,
      },
    ],
  },
  {
    color: "#BF360C",
    short: "5M",
    title: "MS Specific",
    links: [
      {
        url: "https://docs.google.com/document/d/1tSqlif9G0zIiCByU3JImjdYMUvwxg4SAL9Xrs7r3_NA/edit?usp=sharing",
        title: "Overview Google Doc + Screencast Links",
      },
    ],
    meta: {
      blockType: "Weekday",
      school: "MS Only",
      variableDistribution: true,
      crossoverBlocks: 0.333,
    },
    blocks: [
      {
        name: "Advisory",
        color: "#c5cae9",
        total: 25.200000000000003,
        academic: true,
      },
      { name: "Lunch", color: "#e0f2f1", total: 28.000000000000004 },
      { name: "Math", color: "#ffccbc", total: 50, academic: true },
      { name: "Spanish", color: "#f1f8e9", total: 40, academic: true },
      { name: "Special", color: "#ffebee", total: 40, academic: true },
      {
        name: "ELA",
        color: "#f9fbe7",
        total: 70.80000000000001,
        academic: true,
      },
      {
        name: "SS",
        color: "#d1c4e9",
        total: 40.400000000000006,
        academic: true,
      },
      {
        name: "Sci",
        color: "#e3f2fd",
        total: 40.400000000000006,
        academic: true,
      },
      { name: "Enrichment", color: "#ffca0a", total: 14, academic: true },
      { name: "Recess", color: "#f3e5f5", total: 8.8 },
      { color: "#fce4ec", name: "WIN", total: 22.8, academic: true },
    ],
    days: [
      {
        name: "M",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 2,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "Math",
              color: "#ffccbc",
              total: 50,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 156,
            height: 150,
            id: 8,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 312,
            height: 150,
            id: 9,
            block: {
              name: "Special",
              color: "#ffebee",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 42,
            passing: 2,
            offset: 468,
            height: 126,
            id: 10,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25.200000000000003,
              academic: true,
            },
          },
          {
            duration: 28,
            passing: 2,
            offset: 600,
            height: 84,
            id: 11,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 28.000000000000004,
            },
          },
          {
            duration: 8,
            passing: 2,
            offset: 690,
            height: 24,
            id: 12,
            block: { name: "Recess", color: "#f3e5f5", total: 8.8 },
          },
          {
            duration: 76,
            passing: 3,
            offset: 720,
            height: 228,
            id: 16,
            block: {
              name: "ELA",
              color: "#f9fbe7",
              total: 70.80000000000001,
              academic: true,
            },
          },
          {
            duration: 76,
            passing: 2,
            offset: 957,
            height: 228,
            id: 20,
            block: {
              name: "Sci",
              color: "#e3f2fd",
              total: 40.400000000000006,
              academic: true,
            },
          },
        ],
        height: 1191,
        start: 480,
      },
      {
        name: "T",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 2,
            offset: 0,
            height: 150,
            id: 7,
            block: {
              name: "Math",
              color: "#ffccbc",
              total: 50,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 156,
            height: 150,
            id: 8,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 312,
            height: 150,
            id: 9,
            block: {
              name: "Special",
              color: "#ffebee",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 42,
            passing: 2,
            offset: 468,
            height: 126,
            id: 10,
            block: {
              color: "#fce4ec",
              name: "WIN",
              total: 22.8,
              academic: true,
            },
          },
          {
            duration: 28,
            passing: 2,
            offset: 600,
            height: 84,
            id: 11,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 28.000000000000004,
            },
          },
          {
            duration: 8,
            passing: 2,
            offset: 690,
            height: 24,
            id: 12,
            block: { name: "Recess", color: "#f3e5f5", total: 8.8 },
          },
          {
            duration: 76,
            passing: 3,
            offset: 720,
            height: 228,
            id: 16,
            block: {
              name: "ELA",
              color: "#f9fbe7",
              total: 70.80000000000001,
              academic: true,
            },
          },
          {
            duration: 76,
            passing: 2,
            offset: 957,
            height: 228,
            id: 20,
            block: {
              name: "SS",
              color: "#d1c4e9",
              total: 40.400000000000006,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1191,
      },
      {
        name: "W",
        repeats: 1,
        blocks: [
          {
            duration: 70,
            passing: 2,
            offset: 0,
            height: 210,
            id: 7,
            block: {
              name: "Enrichment",
              color: "#ffca0a",
              total: 14,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 216,
            height: 150,
            block: {
              name: "Sci",
              color: "#e3f2fd",
              total: 40.400000000000006,
              academic: true,
            },
            id: 24,
          },
          {
            duration: 30,
            passing: 2,
            offset: 372,
            height: 90,
            id: 28,
            block: {
              color: "#fce4ec",
              name: "WIN",
              total: 22.8,
              academic: true,
            },
          },
          {
            duration: 42,
            passing: 2,
            offset: 468,
            height: 126,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25.200000000000003,
              academic: true,
            },
            id: 32,
          },
          {
            duration: 28,
            passing: 2,
            offset: 600,
            height: 84,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 28.000000000000004,
            },
            id: 36,
          },
          {
            duration: 8,
            passing: 3,
            offset: 690,
            height: 24,
            block: { name: "Recess", color: "#f3e5f5", total: 8.8 },
            id: 40,
          },
          {
            duration: 50,
            passing: 2,
            offset: 723,
            height: 150,
            block: {
              name: "SS",
              color: "#d1c4e9",
              total: 40.400000000000006,
              academic: true,
            },
            id: 44,
          },
          {
            duration: 50,
            passing: 2,
            offset: 879,
            height: 150,
            block: {
              name: "ELA",
              color: "#f9fbe7",
              total: 70.80000000000001,
              academic: true,
            },
            id: 48,
          },
          {
            duration: 50,
            passing: 2,
            offset: 1035,
            height: 150,
            block: {
              name: "Math",
              color: "#ffccbc",
              total: 50,
              academic: true,
            },
            id: 52,
          },
        ],
        start: 480,
        height: 1191,
      },
      {
        name: "R",
        repeats: 1,
        blocks: [
          {
            duration: 76,
            passing: 3,
            offset: 0,
            height: 228,
            id: 16,
            block: {
              name: "ELA",
              color: "#f9fbe7",
              total: 70.80000000000001,
              academic: true,
            },
          },
          {
            duration: 76,
            passing: 2,
            offset: 237,
            height: 228,
            id: 20,
            block: {
              name: "Sci",
              color: "#e3f2fd",
              total: 40.400000000000006,
              academic: true,
            },
          },
          {
            duration: 42,
            passing: 2,
            offset: 471,
            height: 126,
            id: 10,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25.200000000000003,
              academic: true,
            },
          },
          {
            duration: 28,
            passing: 0,
            offset: 603,
            height: 84,
            id: 11,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 28.000000000000004,
            },
          },
          {
            duration: 10,
            passing: 2,
            offset: 687,
            height: 30,
            id: 12,
            block: { name: "Recess", color: "#f3e5f5", total: 8.8 },
          },
          {
            duration: 50,
            passing: 2,
            offset: 723,
            height: 150,
            id: 7,
            block: {
              name: "Math",
              color: "#ffccbc",
              total: 50,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 879,
            height: 150,
            id: 8,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 1035,
            height: 150,
            id: 9,
            block: {
              name: "Special",
              color: "#ffebee",
              total: 40,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1191,
      },
      {
        name: "F",
        repeats: 1,
        blocks: [
          {
            duration: 76,
            passing: 3,
            offset: 0,
            height: 228,
            id: 16,
            block: {
              name: "ELA",
              color: "#f9fbe7",
              total: 70.80000000000001,
              academic: true,
            },
          },
          {
            duration: 76,
            passing: 2,
            offset: 237,
            height: 228,
            id: 20,
            block: {
              name: "SS",
              color: "#d1c4e9",
              total: 40.400000000000006,
              academic: true,
            },
          },
          {
            duration: 42,
            passing: 2,
            offset: 471,
            height: 126,
            id: 10,
            block: {
              color: "#fce4ec",
              name: "WIN",
              total: 22.8,
              academic: true,
            },
          },
          {
            duration: 28,
            passing: 0,
            offset: 603,
            height: 84,
            id: 11,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 28.000000000000004,
            },
          },
          {
            duration: 10,
            passing: 2,
            offset: 687,
            height: 30,
            id: 12,
            block: { name: "Recess", color: "#f3e5f5", total: 8.8 },
          },
          {
            duration: 50,
            passing: 2,
            offset: 723,
            height: 150,
            id: 7,
            block: {
              name: "Math",
              color: "#ffccbc",
              total: 50,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 879,
            height: 150,
            id: 8,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 2,
            offset: 1035,
            height: 150,
            id: 9,
            block: {
              name: "Special",
              color: "#ffebee",
              total: 40,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1191,
      },
    ],
  },
  {
    color: "#FF6F00",
    short: "5H",
    title: "HS 3-Short 1-Long / Week + Short Daily Advisory",
    links: [
      {
        url: "https://docs.google.com/document/d/1DAZB4DKN6WHqrEKVtArgHtndITThiHXhA4DtTU0kiK0/edit?usp=sharing",
        title: "Overview document",
      },
    ],
    meta: {
      blockType: "Weekday",
      variableDistribution: false,
      crossoverBlocks: 0.333,
    },
    blocks: [
      { name: "Advisory", color: "#c5cae9", total: 14, academic: true },
      { name: "Lunch", color: "#e0f2f1", total: 25 },
      { name: "A", color: "#ffccbc", total: 51.00000000000001, academic: true },
      { name: "B", color: "#f1f8e9", total: 51.00000000000001, academic: true },
      { name: "C", color: "#ffebee", total: 51.00000000000001, academic: true },
      { name: "D", color: "#f9fbe7", total: 51, academic: true },
      { name: "E", color: "#d1c4e9", total: 51.00000000000001, academic: true },
      { name: "F", color: "#e3f2fd", total: 51.00000000000001, academic: true },
      { name: "Choice", color: "#ffbb00", total: 10, academic: true },
      { name: "Long Pass", color: "#f3e5f5", total: 5 },
    ],
    days: [
      {
        name: "M",
        repeats: 1,
        blocks: [
          {
            duration: 15,
            passing: 5,
            offset: 0,
            height: 45,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 14,
              academic: true,
            },
          },
          {
            duration: 63,
            passing: 5,
            offset: 60,
            height: 189,
            id: 8,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 264,
            height: 15,
            id: 9,
            block: { name: "Long Pass", color: "#f3e5f5", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 294,
            height: 234,
            id: 10,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 58,
            passing: 5,
            offset: 543,
            height: 174,
            id: 11,
            block: {
              name: "C",
              color: "#ffebee",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 58,
            passing: 5,
            offset: 732,
            height: 174,
            id: 12,
            block: { name: "D", color: "#f9fbe7", total: 51, academic: true },
          },
          {
            duration: 25,
            passing: 5,
            offset: 921,
            height: 75,
            block: { name: "Lunch", color: "#e0f2f1", total: 25 },
            id: 16,
          },
          {
            duration: 58,
            passing: 5,
            offset: 1011,
            height: 174,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 51.00000000000001,
              academic: true,
            },
            id: 20,
          },
        ],
        height: 1200,
        start: 480,
      },
      {
        name: "T",
        repeats: 1,
        blocks: [
          {
            duration: 15,
            passing: 5,
            offset: 0,
            height: 45,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 14,
              academic: true,
            },
          },
          {
            duration: 63,
            passing: 5,
            offset: 60,
            height: 189,
            id: 8,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 264,
            height: 15,
            id: 9,
            block: { name: "Long Pass", color: "#f3e5f5", total: 5 },
          },
          {
            duration: 76,
            passing: 5,
            offset: 294,
            height: 228,
            id: 10,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 61,
            passing: 5,
            offset: 537,
            height: 183,
            id: 11,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 58,
            passing: 5,
            offset: 735,
            height: 174,
            id: 12,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 924,
            height: 75,
            block: { name: "Lunch", color: "#e0f2f1", total: 25 },
            id: 16,
          },
          {
            duration: 57,
            passing: 5,
            offset: 1014,
            height: 171,
            block: { name: "D", color: "#f9fbe7", total: 51, academic: true },
            id: 20,
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "W",
        repeats: 1,
        blocks: [
          {
            duration: 10,
            passing: 5,
            offset: 0,
            height: 30,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 14,
              academic: true,
            },
          },
          {
            duration: 76,
            passing: 5,
            offset: 45,
            height: 228,
            id: 8,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 288,
            height: 15,
            id: 9,
            block: { name: "Long Pass", color: "#f3e5f5", total: 5 },
          },
          {
            duration: 77,
            passing: 5,
            offset: 318,
            height: 231,
            id: 10,
            block: { name: "D", color: "#f9fbe7", total: 51, academic: true },
          },
          {
            duration: 61,
            passing: 5,
            offset: 564,
            height: 183,
            id: 11,
            block: {
              name: "C",
              color: "#ffebee",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 56,
            passing: 5,
            offset: 762,
            height: 168,
            id: 12,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 945,
            height: 75,
            block: { name: "Lunch", color: "#e0f2f1", total: 25 },
            id: 16,
          },
          {
            duration: 50,
            passing: 5,
            offset: 1035,
            height: 150,
            block: {
              name: "Choice",
              color: "#ffbb00",
              total: 10,
              academic: true,
            },
            id: 20,
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "Th",
        repeats: 1,
        blocks: [
          {
            duration: 15,
            passing: 5,
            offset: 0,
            height: 45,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 14,
              academic: true,
            },
          },
          {
            duration: 63,
            passing: 5,
            offset: 60,
            height: 189,
            id: 8,
            block: { name: "D", color: "#f9fbe7", total: 51, academic: true },
          },
          {
            duration: 5,
            passing: 5,
            offset: 264,
            height: 15,
            id: 9,
            block: { name: "Long Pass", color: "#f3e5f5", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 294,
            height: 234,
            id: 10,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 58,
            passing: 5,
            offset: 543,
            height: 174,
            id: 11,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 58,
            passing: 5,
            offset: 732,
            height: 174,
            id: 12,
            block: {
              name: "C",
              color: "#ffebee",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 921,
            height: 75,
            block: { name: "Lunch", color: "#e0f2f1", total: 25 },
            id: 16,
          },
          {
            duration: 58,
            passing: 5,
            offset: 1011,
            height: 174,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 51.00000000000001,
              academic: true,
            },
            id: 20,
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "F",
        repeats: 1,
        blocks: [
          {
            duration: 15,
            passing: 5,
            offset: 0,
            height: 45,
            id: 7,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 14,
              academic: true,
            },
          },
          {
            duration: 63,
            passing: 5,
            offset: 60,
            height: 189,
            id: 8,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 264,
            height: 15,
            id: 9,
            block: { name: "Long Pass", color: "#f3e5f5", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 294,
            height: 234,
            id: 10,
            block: {
              name: "C",
              color: "#ffebee",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 58,
            passing: 5,
            offset: 543,
            height: 174,
            id: 11,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 58,
            passing: 5,
            offset: 732,
            height: 174,
            id: 12,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 51.00000000000001,
              academic: true,
            },
          },
          {
            duration: 25,
            passing: 5,
            offset: 921,
            height: 75,
            block: { name: "Lunch", color: "#e0f2f1", total: 25 },
            id: 16,
          },
          {
            duration: 58,
            passing: 5,
            offset: 1011,
            height: 174,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 51.00000000000001,
              academic: true,
            },
            id: 20,
          },
        ],
        start: 480,
        height: 1200,
      },
    ],
  },

  {
    title: "Current MS Schedule (for reference only)",
    color: "#919191",
    short: "MS",
    meta: {
      blockType: "Weekday",
      variableDistribution: true,
      hide: true,
      crossoverBlocks: 0,
    },
    blocks: [
      { name: "Advisory", color: "#c5cae9", total: 24, academic: true },
      {
        name: "Lunch",
        color: "#e0f2f1",
        total: 13.600000000000001,
        academic: false,
      },
      { name: "ELA", color: "#ffccbc", total: 61, academic: true },
      { name: "Spanish", color: "#f1f8e9", total: 40, academic: true },
      { name: "Specials", color: "#ffebee", total: 41, academic: true },
      { name: "Project", color: "#f9fbe7", total: 93.4, academic: true },
      { name: "Math", color: "#d1c4e9", total: 51, academic: true },
      { name: "WIN", color: "#e3f2fd", total: 24, academic: true },
      { name: "Recess", color: "#efebe9", total: 16.4 },
    ],
    days: [
      {
        name: "M",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 3,
            offset: 0,
            height: 150,
            id: 1,
            block: { name: "ELA", color: "#ffccbc", total: 61, academic: true },
          },
          {
            duration: 50,
            passing: 3,
            offset: 159,
            height: 150,
            id: 2,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 318,
            height: 150,
            id: 3,
            block: {
              name: "Specials",
              color: "#ffebee",
              total: 41,
              academic: true,
            },
          },
          {
            duration: 30,
            passing: 3,
            id: 7,
            offset: 477,
            height: 90,
            block: { name: "WIN", color: "#e3f2fd", total: 24, academic: true },
          },
          {
            duration: 17,
            passing: 3,
            offset: 576,
            height: 51,
            id: 4,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 13.600000000000001,
              academic: false,
            },
          },
          {
            duration: 13,
            passing: 3,
            offset: 636,
            height: 39,
            id: 5,
            block: { name: "Recess", color: "#efebe9", total: 16.4 },
          },
          {
            duration: 30,
            passing: 3,
            offset: 684,
            height: 90,
            id: 6,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 783,
            height: 150,
            id: 11,
            block: {
              name: "Math",
              color: "#d1c4e9",
              total: 51,
              academic: true,
            },
          },
          {
            duration: 103,
            passing: 3,
            offset: 942,
            height: 309,
            block: {
              name: "Project",
              color: "#f9fbe7",
              total: 93.4,
              academic: true,
            },
            id: 15,
          },
        ],
        height: 1260,
        start: 480,
      },
      {
        name: "T",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 3,
            offset: 0,
            height: 150,
            id: 1,
            block: { name: "ELA", color: "#ffccbc", total: 61, academic: true },
          },
          {
            duration: 50,
            passing: 3,
            offset: 159,
            height: 150,
            id: 2,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 318,
            height: 150,
            id: 3,
            block: {
              name: "Specials",
              color: "#ffebee",
              total: 41,
              academic: true,
            },
          },
          {
            duration: 30,
            passing: 3,
            id: 7,
            offset: 477,
            height: 90,
            block: { name: "WIN", color: "#e3f2fd", total: 24, academic: true },
          },
          {
            duration: 17,
            passing: 3,
            offset: 576,
            height: 51,
            id: 4,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 13.600000000000001,
              academic: false,
            },
          },
          {
            duration: 13,
            passing: 3,
            offset: 636,
            height: 39,
            id: 5,
            block: { name: "Recess", color: "#efebe9", total: 16.4 },
          },
          {
            duration: 30,
            passing: 3,
            offset: 684,
            height: 90,
            id: 6,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 783,
            height: 150,
            id: 11,
            block: {
              name: "Math",
              color: "#d1c4e9",
              total: 51,
              academic: true,
            },
          },
          {
            duration: 103,
            passing: 3,
            offset: 942,
            height: 309,
            block: {
              name: "Project",
              color: "#f9fbe7",
              total: 93.4,
              academic: true,
            },
            id: 15,
          },
        ],
        start: 480,
        height: 1260,
      },
      {
        name: "W",
        repeats: 1,
        blocks: [
          {
            duration: 55,
            passing: 3,
            offset: 0,
            height: 165,
            id: 1,
            block: { name: "ELA", color: "#ffccbc", total: 61, academic: true },
          },
          {
            duration: 55,
            passing: 3,
            offset: 174,
            height: 165,
            id: 3,
            block: {
              name: "Specials",
              color: "#ffebee",
              total: 41,
              academic: true,
            },
          },
          {
            duration: 30,
            passing: 3,
            offset: 348,
            height: 90,
            id: 5,
            block: { name: "Recess", color: "#efebe9", total: 16.4 },
          },
          {
            duration: 55,
            passing: 3,
            id: 7,
            offset: 447,
            height: 165,
            block: {
              name: "Project",
              color: "#f9fbe7",
              total: 93.4,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 3,
            offset: 621,
            height: 165,
            id: 4,
            block: {
              name: "Math",
              color: "#d1c4e9",
              total: 51,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 795,
      },
      {
        name: "R",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 3,
            offset: 0,
            height: 150,
            id: 1,
            block: { name: "ELA", color: "#ffccbc", total: 61, academic: true },
          },
          {
            duration: 50,
            passing: 3,
            offset: 159,
            height: 150,
            id: 2,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 318,
            height: 150,
            id: 3,
            block: { name: "ELA", color: "#ffccbc", total: 61, academic: true },
          },
          {
            duration: 30,
            passing: 3,
            id: 7,
            offset: 477,
            height: 90,
            block: { name: "WIN", color: "#e3f2fd", total: 24, academic: true },
          },
          {
            duration: 17,
            passing: 3,
            offset: 576,
            height: 51,
            id: 4,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 13.600000000000001,
              academic: false,
            },
          },
          {
            duration: 13,
            passing: 3,
            offset: 636,
            height: 39,
            id: 5,
            block: { name: "Recess", color: "#efebe9", total: 16.4 },
          },
          {
            duration: 30,
            passing: 3,
            offset: 684,
            height: 90,
            id: 6,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 783,
            height: 150,
            id: 11,
            block: {
              name: "Math",
              color: "#d1c4e9",
              total: 51,
              academic: true,
            },
          },
          {
            duration: 103,
            passing: 3,
            offset: 942,
            height: 309,
            block: {
              name: "Project",
              color: "#f9fbe7",
              total: 93.4,
              academic: true,
            },
            id: 15,
          },
        ],
        start: 480,
        height: 1260,
      },
      {
        name: "F",
        repeats: 1,
        blocks: [
          {
            duration: 50,
            passing: 3,
            offset: 0,
            height: 150,
            id: 1,
            block: { name: "ELA", color: "#ffccbc", total: 61, academic: true },
          },
          {
            duration: 50,
            passing: 3,
            offset: 159,
            height: 150,
            id: 2,
            block: {
              name: "Spanish",
              color: "#f1f8e9",
              total: 40,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 318,
            height: 150,
            id: 3,
            block: {
              name: "Specials",
              color: "#ffebee",
              total: 41,
              academic: true,
            },
          },
          {
            duration: 30,
            passing: 3,
            id: 7,
            offset: 477,
            height: 90,
            block: { name: "WIN", color: "#e3f2fd", total: 24, academic: true },
          },
          {
            duration: 17,
            passing: 3,
            offset: 576,
            height: 51,
            id: 4,
            block: {
              name: "Lunch",
              color: "#e0f2f1",
              total: 13.600000000000001,
              academic: false,
            },
          },
          {
            duration: 13,
            passing: 3,
            offset: 636,
            height: 39,
            id: 5,
            block: { name: "Recess", color: "#efebe9", total: 16.4 },
          },
          {
            duration: 30,
            passing: 3,
            offset: 684,
            height: 90,
            id: 6,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24,
              academic: true,
            },
          },
          {
            duration: 50,
            passing: 3,
            offset: 783,
            height: 150,
            id: 11,
            block: {
              name: "Math",
              color: "#d1c4e9",
              total: 51,
              academic: true,
            },
          },
          {
            duration: 103,
            passing: 3,
            offset: 942,
            height: 309,
            block: {
              name: "Project",
              color: "#f9fbe7",
              total: 93.4,
              academic: true,
            },
            id: 15,
          },
        ],
        start: 480,
        height: 1260,
      },
    ],
  },

  {
    title: "Current HS Schedule (for reference only)",
    color: "#616161",
    short: "HS",
    meta: {
      blockType: "Weekday",
      variableDistribution: false,
      hide: true,
      crossoverBlocks: 0,
    },
    blocks: [
      { name: "Advisory", color: "#c5cae9", total: 24, academic: true },
      { name: "Lunch", color: "#e0f2f1", total: 24 },
      { name: "A", color: "#ffccbc", total: 49, academic: true },
      { name: "B", color: "#f1f8e9", total: 48, academic: true },
      { name: "C", color: "#ffebee", total: 49, academic: true },
      { name: "D", color: "#f9fbe7", total: 49, academic: true },
      { name: "E", color: "#d1c4e9", total: 49, academic: true },
      { name: "F", color: "#e3f2fd", total: 49, academic: true },
      { name: "Long Pass", color: "#efebe9", total: 5 },
      { color: "#f3e5f5", name: "Choice", total: 16, academic: true },
    ],
    days: [
      {
        name: "Day 1",
        repeats: 1,
        blocks: [
          {
            duration: 85,
            passing: 5,
            offset: 0,
            height: 255,
            id: 1,
            block: { name: "A", color: "#ffccbc", total: 49 },
          },
          {
            duration: 5,
            passing: 5,
            offset: 270,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#efebe9", total: 5 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 300,
            height: 240,
            id: 3,
            block: { name: "B", color: "#f1f8e9", total: 48 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 555,
            height: 240,
            id: 4,
            block: { name: "C", color: "#ffebee", total: 49 },
          },
          {
            duration: 30,
            passing: 5,
            offset: 810,
            height: 90,
            id: 5,
            block: { name: "Advisory", color: "#c5cae9", total: 24 },
          },
          {
            duration: 30,
            passing: 5,
            id: 8,
            offset: 915,
            height: 90,
            block: { name: "Lunch", color: "#e0f2f1", total: 24 },
          },
          {
            duration: 80,
            passing: 5,
            id: 7,
            offset: 1020,
            height: 240,
            block: { name: "D", color: "#f9fbe7", total: 49 },
          },
        ],
        height: 1275,
        start: 480,
      },
      {
        name: "Day 2",
        repeats: 1,
        blocks: [
          {
            duration: 85,
            passing: 5,
            offset: 0,
            height: 255,
            id: 1,
            block: { name: "B", color: "#d1c4e9", total: 49 },
          },
          {
            duration: 5,
            passing: 5,
            offset: 270,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#efebe9", total: 5 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 300,
            height: 240,
            id: 3,
            block: { name: "F", color: "#e3f2fd", total: 49 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 555,
            height: 240,
            id: 4,
            block: { name: "E", color: "#ffccbc", total: 49 },
          },
          {
            duration: 30,
            passing: 5,
            offset: 810,
            height: 90,
            id: 5,
            block: { name: "Advisory", color: "#c5cae9", total: 24 },
          },
          {
            duration: 30,
            passing: 5,
            id: 8,
            offset: 915,
            height: 90,
            block: { name: "Lunch", color: "#e0f2f1", total: 24 },
          },
          {
            duration: 80,
            passing: 5,
            id: 7,
            offset: 1020,
            height: 240,
            block: { name: "C", color: "#f1f8e9", total: 48 },
          },
        ],
        start: 480,
        height: 1275,
      },
      {
        name: "Day 3",
        repeats: 1,
        blocks: [
          {
            duration: 85,
            passing: 5,
            offset: 0,
            height: 255,
            id: 1,
            block: { name: "A", color: "#ffebee", total: 49 },
          },
          {
            duration: 5,
            passing: 5,
            offset: 270,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#efebe9", total: 5 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 300,
            height: 240,
            id: 3,
            block: { name: "E", color: "#f9fbe7", total: 49 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 555,
            height: 240,
            id: 4,
            block: { name: "F", color: "#d1c4e9", total: 49 },
          },
        ],
        start: 480,
        height: 810,
      },
      {
        name: "Day 4",
        repeats: 1,
        blocks: [
          {
            duration: 85,
            passing: 5,
            offset: 0,
            height: 255,
            id: 1,
            block: { name: "C", color: "#e3f2fd", total: 49 },
          },
          {
            duration: 5,
            passing: 5,
            offset: 270,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#efebe9", total: 5 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 300,
            height: 240,
            id: 3,
            block: { name: "B", color: "#ffccbc", total: 49 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 555,
            height: 240,
            id: 4,
            block: { name: "D", color: "#f1f8e9", total: 48 },
          },
          {
            duration: 30,
            passing: 5,
            offset: 810,
            height: 90,
            id: 5,
            block: { name: "Advisory", color: "#c5cae9", total: 24 },
          },
          {
            duration: 30,
            passing: 5,
            id: 8,
            offset: 915,
            height: 90,
            block: { name: "Lunch", color: "#e0f2f1", total: 24 },
          },
          {
            duration: 80,
            passing: 5,
            id: 7,
            offset: 1020,
            height: 240,
            block: { name: "A", color: "#ffebee", total: 49 },
          },
        ],
        start: 480,
        height: 1275,
      },
      {
        name: "Day 5",
        repeats: 1,
        blocks: [
          {
            duration: 85,
            passing: 5,
            offset: 0,
            height: 255,
            id: 1,
            block: { name: "D", color: "#f9fbe7", total: 49 },
          },
          {
            duration: 5,
            passing: 5,
            offset: 270,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#efebe9", total: 5 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 300,
            height: 240,
            id: 3,
            block: { name: "F", color: "#d1c4e9", total: 49 },
          },
          {
            duration: 80,
            passing: 5,
            offset: 555,
            height: 240,
            id: 4,
            block: { name: "E", color: "#e3f2fd", total: 49 },
          },
          {
            duration: 30,
            passing: 5,
            offset: 810,
            height: 90,
            id: 5,
            block: { name: "Advisory", color: "#c5cae9", total: 24 },
          },
          {
            duration: 30,
            passing: 5,
            id: 8,
            offset: 915,
            height: 90,
            block: { name: "Lunch", color: "#e0f2f1", total: 24 },
          },
          {
            duration: 80,
            passing: 5,
            id: 7,
            offset: 1020,
            height: 240,
            block: { color: "#f3e5f5", name: "Choice", total: 16 },
          },
        ],
        start: 480,
        height: 1275,
      },
    ],
  },
  {
    title: "Traditional Bell Schedule (for reference only)",
    color: "#9E9E9E",
    short: "T",
    meta: {
      blockType: "Weekday",
      hide: true,
    },
    blocks: [
      { name: "Advisory", color: "#c5cae9", total: 5, academic: true },
      { name: "Lunch", color: "#e0f2f1", total: 15 },
      { name: "A", color: "#ffccbc", total: 55, academic: true },
      { name: "B", color: "#f1f8e9", total: 55, academic: true },
      { name: "C", color: "#ffebee", total: 55, academic: true },
      { name: "D", color: "#f9fbe7", total: 55, academic: true },
      { name: "E", color: "#d1c4e9", total: 55, academic: true },
      { name: "F", color: "#e3f2fd", total: 55, academic: true },
      { name: "Choice", color: "#efebe9", total: 0, academic: true },
    ],
    days: [
      {
        name: "Day 1",
        repeats: 1,
        blocks: [
          {
            duration: 5,
            passing: 5,
            id: 11,
            offset: 0,
            height: 15,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 5,
              academic: true,
            },
          },
          {
            duration: 55,
            passing: 5,
            offset: 30,
            height: 165,
            id: 1,
            block: { name: "A", color: "#ffccbc", total: 55, academic: true },
          },
          {
            duration: 55,
            passing: 5,
            offset: 210,
            height: 165,
            id: 2,
            block: { name: "B", color: "#f1f8e9", total: 55, academic: true },
          },
          {
            duration: 55,
            passing: 5,
            offset: 390,
            height: 165,
            id: 3,
            block: { name: "C", color: "#ffebee", total: 55, academic: true },
          },
          {
            duration: 15,
            passing: 5,
            offset: 570,
            height: 45,
            block: { name: "Lunch", color: "#e0f2f1", total: 15 },
            id: 15,
          },
          {
            duration: 55,
            passing: 5,
            offset: 630,
            height: 165,
            id: 4,
            block: { name: "D", color: "#f9fbe7", total: 55, academic: true },
          },
          {
            duration: 55,
            passing: 5,
            offset: 810,
            height: 165,
            id: 5,
            block: { name: "E", color: "#d1c4e9", total: 55, academic: true },
          },
          {
            duration: 55,
            passing: 5,
            offset: 990,
            height: 165,
            id: 6,
            block: { name: "F", color: "#e3f2fd", total: 55, academic: true },
          },
        ],
        height: 1170,
        start: 480,
      },
    ],
  },
  {
    short: "6H",
    title: "HS Even Blocks 4/5 Weekday Schedule",
    color: "#5D4037",
    meta: {
      blockType: "Weekday",
      variableDistribution: false,
      crossoverBlocks: 0,
    },
    blocks: [
      { name: "Advisory", color: "#c5cae9", total: 25, academic: true },
      { name: "Lunch", color: "#e0f2f1", total: 20 },
      { name: "A", color: "#ffccbc", total: 49.6, academic: true },
      { name: "B", color: "#f1f8e9", total: 49.6, academic: true },
      { name: "C", color: "#ffebee", total: 49.6, academic: true },
      { name: "D", color: "#f9fbe7", total: 49.6, academic: true },
      { name: "E", color: "#d1c4e9", total: 49.6, academic: true },
      { name: "F", color: "#e3f2fd", total: 49.6, academic: true },
      { name: "Choice", color: "#fdba2b", total: 12.4, academic: true },
      { name: "Long Pass", color: "#f3e5f5", total: 15, academic: false },
    ],
    days: [
      {
        name: "M",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 0,
            offset: 90,
            height: 186,
            id: 1,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            offset: 276,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 15,
              academic: false,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 321,
            height: 186,
            id: 3,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 522,
            height: 186,
            id: 4,
            block: {
              name: "C",
              color: "#ffebee",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 723,
            height: 186,
            id: 5,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 924,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 62,
            passing: 5,
            offset: 999,
            height: 186,
            id: 6,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 49.6,
              academic: true,
            },
          },
        ],
        height: 1200,
        start: 480,
      },
      {
        name: "T",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 0,
            offset: 90,
            height: 186,
            id: 1,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            offset: 276,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 15,
              academic: false,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 321,
            height: 186,
            id: 3,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 522,
            height: 186,
            id: 4,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 723,
            height: 186,
            id: 5,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 924,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 62,
            passing: 5,
            offset: 999,
            height: 186,
            id: 6,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 49.6,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "W",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 0,
            offset: 90,
            height: 186,
            id: 1,
            block: {
              name: "C",
              color: "#ffebee",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            offset: 276,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 15,
              academic: false,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 321,
            height: 186,
            id: 3,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 522,
            height: 186,
            id: 4,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 723,
            height: 186,
            id: 5,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 924,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 62,
            passing: 5,
            offset: 999,
            height: 186,
            id: 6,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 49.6,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "Th",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 0,
            offset: 90,
            height: 186,
            id: 1,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            offset: 276,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 15,
              academic: false,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 321,
            height: 186,
            id: 3,
            block: {
              name: "C",
              color: "#ffebee",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 522,
            height: 186,
            id: 4,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 723,
            height: 186,
            id: 5,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 924,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 62,
            passing: 5,
            offset: 999,
            height: 186,
            id: 6,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 49.6,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "F",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 25,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 0,
            offset: 90,
            height: 186,
            id: 1,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            offset: 276,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 15,
              academic: false,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 321,
            height: 186,
            id: 3,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 522,
            height: 186,
            id: 4,
            block: {
              name: "C",
              color: "#ffebee",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 62,
            passing: 5,
            offset: 723,
            height: 186,
            id: 5,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 49.6,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 924,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 62,
            passing: 5,
            offset: 999,
            height: 186,
            id: 6,
            block: {
              name: "Choice",
              color: "#fdba2b",
              total: 12.4,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
    ],
    title: "4/5 Day 62 minute classes",
  },
  {
    short: "7H",
    color: "#E65100",
    meta: {
      blockType: "Weekday",
      variableDistribution: false,
      hide: false,
      crossoverBlocks: 0,
    },
    blocks: [
      { name: "Advisory", color: "#c5cae9", total: 20, academic: true },
      { name: "Lunch", color: "#e0f2f1", total: 20 },
      { name: "A", color: "#ffccbc", total: 50.4, academic: true },
      { name: "B", color: "#f1f8e9", total: 50.4, academic: true },
      { name: "C", color: "#ffebee", total: 50.4, academic: true },
      {
        name: "D",
        color: "#f9fbe7",
        total: 50.400000000000006,
        academic: true,
      },
      { name: "E", color: "#d1c4e9", total: 50.4, academic: true },
      { name: "F", color: "#e3f2fd", total: 50.4, academic: true },
      { name: "Choice", color: "#fdba2b", total: 11.4, academic: true },
      { name: "Long Pass", color: "#f3e5f5", total: 16.2, academic: false },
    ],
    days: [
      {
        name: "M",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 20,
              academic: true,
            },
          },
          {
            duration: 57,
            passing: 0,
            offset: 90,
            height: 171,
            id: 1,
            block: { name: "A", color: "#ffccbc", total: 50.4, academic: true },
          },
          {
            duration: 15,
            passing: 0,
            offset: 261,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 16.2,
              academic: false,
            },
          },
          {
            duration: 81,
            passing: 5,
            offset: 306,
            height: 243,
            id: 3,
            block: { name: "F", color: "#e3f2fd", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 564,
            height: 171,
            id: 4,
            block: { name: "E", color: "#d1c4e9", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 750,
            height: 171,
            id: 5,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 50.400000000000006,
              academic: true,
            },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 936,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 57,
            passing: 5,
            offset: 1011,
            height: 171,
            id: 6,
            block: { name: "C", color: "#ffebee", total: 50.4, academic: true },
          },
        ],
        height: 1197,
        start: 480,
      },
      {
        name: "T",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 20,
              academic: true,
            },
          },
          {
            duration: 57,
            passing: 0,
            offset: 90,
            height: 171,
            id: 1,
            block: { name: "B", color: "#f1f8e9", total: 50.4, academic: true },
          },
          {
            duration: 15,
            passing: 0,
            offset: 261,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 16.2,
              academic: false,
            },
          },
          {
            duration: 81,
            passing: 5,
            offset: 306,
            height: 243,
            id: 3,
            block: { name: "A", color: "#ffccbc", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 564,
            height: 171,
            id: 4,
            block: { name: "F", color: "#e3f2fd", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 750,
            height: 171,
            id: 5,
            block: { name: "E", color: "#d1c4e9", total: 50.4, academic: true },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 936,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 57,
            passing: 5,
            offset: 1011,
            height: 171,
            id: 6,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 50.400000000000006,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1197,
      },
      {
        name: "W",
        repeats: 1,
        blocks: [
          {
            duration: 81,
            passing: 0,
            offset: 0,
            height: 243,
            id: 1,
            block: { name: "E", color: "#d1c4e9", total: 50.4, academic: true },
          },
          {
            duration: 21,
            passing: 0,
            offset: 243,
            height: 63,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 16.2,
              academic: false,
            },
          },
          {
            duration: 81,
            passing: 5,
            offset: 306,
            height: 243,
            id: 3,
            block: { name: "B", color: "#f1f8e9", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 564,
            height: 171,
            id: 4,
            block: { name: "A", color: "#ffccbc", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 750,
            height: 171,
            id: 5,
            block: { name: "F", color: "#e3f2fd", total: 50.4, academic: true },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 936,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 57,
            passing: 5,
            offset: 1011,
            height: 171,
            id: 6,
            block: { name: "C", color: "#ffebee", total: 50.4, academic: true },
          },
        ],
        start: 480,
        height: 1197,
      },
      {
        name: "Th",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 20,
              academic: true,
            },
          },
          {
            duration: 57,
            passing: 0,
            offset: 90,
            height: 171,
            id: 1,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 50.400000000000006,
              academic: true,
            },
          },
          {
            duration: 15,
            passing: 0,
            offset: 261,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 16.2,
              academic: false,
            },
          },
          {
            duration: 81,
            passing: 5,
            offset: 306,
            height: 243,
            id: 3,
            block: { name: "C", color: "#ffebee", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 564,
            height: 171,
            id: 4,
            block: { name: "B", color: "#f1f8e9", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 750,
            height: 171,
            id: 5,
            block: { name: "A", color: "#ffccbc", total: 50.4, academic: true },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 936,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 57,
            passing: 5,
            offset: 1011,
            height: 171,
            id: 6,
            block: { name: "F", color: "#e3f2fd", total: 50.4, academic: true },
          },
        ],
        start: 480,
        height: 1197,
      },
      {
        name: "F",
        repeats: 1,
        blocks: [
          {
            duration: 25,
            passing: 5,
            id: 7,
            offset: 0,
            height: 75,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 20,
              academic: true,
            },
          },
          {
            duration: 57,
            passing: 0,
            offset: 90,
            height: 171,
            id: 1,
            block: { name: "C", color: "#ffebee", total: 50.4, academic: true },
          },
          {
            duration: 15,
            passing: 0,
            offset: 261,
            height: 45,
            id: 2,
            block: {
              name: "Long Pass",
              color: "#f3e5f5",
              total: 16.2,
              academic: false,
            },
          },
          {
            duration: 81,
            passing: 5,
            offset: 306,
            height: 243,
            id: 3,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 50.400000000000006,
              academic: true,
            },
          },
          {
            duration: 57,
            passing: 5,
            offset: 564,
            height: 171,
            id: 4,
            block: { name: "E", color: "#d1c4e9", total: 50.4, academic: true },
          },
          {
            duration: 57,
            passing: 5,
            offset: 750,
            height: 171,
            id: 5,
            block: { name: "B", color: "#f1f8e9", total: 50.4, academic: true },
          },
          {
            duration: 20,
            passing: 5,
            id: 8,
            offset: 936,
            height: 60,
            block: { name: "Lunch", color: "#e0f2f1", total: 20 },
          },
          {
            duration: 57,
            passing: 5,
            offset: 1011,
            height: 171,
            id: 6,
            block: {
              name: "Choice",
              color: "#fdba2b",
              total: 11.4,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1197,
      },
    ],
    title: "3 Short, 1 Long / Week HS Schedule",
  },
  {
    title: "3 Long / week + Double Choice",
    short: "8H",
    color: "#2E7D32",
    meta: {
      blockType: "Weekday",
      variableDistribution: false,
      hide: false,
      crossoverBlocks: 0,
    },
    blocks: [
      {
        name: "Advisory",
        color: "#c5cae9",
        total: 24.000000000000004,
        academic: true,
      },
      { name: "Lunch", color: "#f9fbfb", total: 24.000000000000004 },
      {
        name: "A",
        color: "#ffccbc",
        total: 46.800000000000004,
        academic: true,
      },
      {
        name: "B",
        color: "#f1f8e9",
        total: 46.800000000000004,
        academic: true,
      },
      {
        name: "C",
        color: "#ffebee",
        total: 46.800000000000004,
        academic: true,
      },
      {
        name: "D",
        color: "#f9fbe7",
        total: 46.800000000000004,
        academic: true,
      },
      {
        name: "E",
        color: "#d1c4e9",
        total: 46.800000000000004,
        academic: true,
      },
      {
        name: "F",
        color: "#e3f2fd",
        total: 46.800000000000004,
        academic: true,
      },
      {
        name: "Choice",
        color: "#ffb029",
        total: 31.200000000000003,
        academic: true,
      },
      { name: "Long Pass", color: "#ffffff", total: 5 },
    ],
    days: [
      {
        name: "M",
        repeats: 1,
        blocks: [
          {
            duration: 78,
            passing: 5,
            offset: 0,
            height: 234,
            id: 1,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 249,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#ffffff", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 279,
            height: 234,
            id: 3,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            id: 7,
            offset: 528,
            height: 234,
            block: {
              name: "C",
              color: "#ffebee",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 777,
            height: 72,
            id: 4,
            block: {
              name: "Lunch",
              color: "#f9fbfb",
              total: 24.000000000000004,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 864,
            height: 72,
            id: 5,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.000000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            offset: 951,
            height: 234,
            id: 6,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 46.800000000000004,
              academic: true,
            },
          },
        ],
        height: 1200,
        start: 480,
      },
      {
        name: "T",
        repeats: 1,
        blocks: [
          {
            duration: 78,
            passing: 5,
            offset: 0,
            height: 234,
            id: 1,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 249,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#ffffff", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 279,
            height: 234,
            id: 3,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            id: 7,
            offset: 528,
            height: 234,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 777,
            height: 72,
            id: 4,
            block: {
              name: "Lunch",
              color: "#f9fbfb",
              total: 24.000000000000004,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 864,
            height: 72,
            id: 5,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.000000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            offset: 951,
            height: 234,
            id: 6,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 46.800000000000004,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "W",
        repeats: 1,
        blocks: [
          {
            duration: 78,
            passing: 5,
            offset: 0,
            height: 234,
            id: 1,
            block: {
              name: "Choice",
              color: "#ffb029",
              total: 31.200000000000003,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 249,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#ffffff", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 279,
            height: 234,
            id: 3,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            id: 7,
            offset: 528,
            height: 234,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 777,
            height: 72,
            id: 4,
            block: {
              name: "Lunch",
              color: "#f9fbfb",
              total: 24.000000000000004,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 864,
            height: 72,
            id: 5,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.000000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            offset: 951,
            height: 234,
            id: 6,
            block: {
              name: "C",
              color: "#ffebee",
              total: 46.800000000000004,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "Th",
        repeats: 1,
        blocks: [
          {
            duration: 78,
            passing: 5,
            offset: 0,
            height: 234,
            id: 1,
            block: {
              name: "C",
              color: "#ffebee",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 249,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#ffffff", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 279,
            height: 234,
            id: 3,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            id: 7,
            offset: 528,
            height: 234,
            block: {
              name: "B",
              color: "#f1f8e9",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 777,
            height: 72,
            id: 4,
            block: {
              name: "Lunch",
              color: "#f9fbfb",
              total: 24.000000000000004,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 864,
            height: 72,
            id: 5,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.000000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            offset: 951,
            height: 234,
            id: 6,
            block: {
              name: "A",
              color: "#ffccbc",
              total: 46.800000000000004,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
      {
        name: "F",
        repeats: 1,
        blocks: [
          {
            duration: 78,
            passing: 5,
            offset: 0,
            height: 234,
            id: 1,
            block: {
              name: "D",
              color: "#f9fbe7",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 5,
            passing: 5,
            offset: 249,
            height: 15,
            id: 2,
            block: { name: "Long Pass", color: "#ffffff", total: 5 },
          },
          {
            duration: 78,
            passing: 5,
            offset: 279,
            height: 234,
            id: 3,
            block: {
              name: "E",
              color: "#d1c4e9",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            id: 7,
            offset: 528,
            height: 234,
            block: {
              name: "F",
              color: "#e3f2fd",
              total: 46.800000000000004,
              academic: true,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 777,
            height: 72,
            id: 4,
            block: {
              name: "Lunch",
              color: "#f9fbfb",
              total: 24.000000000000004,
            },
          },
          {
            duration: 24,
            passing: 5,
            offset: 864,
            height: 72,
            id: 5,
            block: {
              name: "Advisory",
              color: "#c5cae9",
              total: 24.000000000000004,
              academic: true,
            },
          },
          {
            duration: 78,
            passing: 5,
            offset: 951,
            height: 234,
            id: 6,
            block: {
              name: "Choice",
              color: "#ffb029",
              total: 31.200000000000003,
              academic: true,
            },
          },
        ],
        start: 480,
        height: 1200,
      },
    ],
  },
];

let colors = {};
let textcolors = {};
function normalizeColorForBlock(b) {
  if (!colors[b.name]) {
    colors[b.name] = b.color;
    textcolors[b.name] = b.textcolor || "black";
  } else {
    b.color = colors[b.name];
    b.textcolor = textcolors[b.name];
  }
}

function normalizeColors(schedules) {
  for (let sched of schedules) {
    for (let b of sched.blocks) {
      normalizeColorForBlock(b);
    }
    for (let d of sched.days) {
      for (let b of d.blocks) {
        normalizeColorForBlock(b.block);
      }
    }
  }
}

normalizeColors(schedules);
normalizeColors(schedules);
normalizeColors(schedules);

schedules.sort((a, b) => (a.short < b.short && -1) || 1);

export let sortedSchedules = writable(schedules);
